import React, { Component } from 'react';
import scrapes from './reviews/reviews.json'

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { searchTerm: null }
  }

  Review = (props) => {
    return (
      <div className="bg-white shadow overflow-hidden sm:rounded-lg m-5">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-bold text-gray-900">{props.value.name} ({props.value.stars} stars)</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">{props.value.title}</p>
        </div>
        <div className="px-4 py-5 sm:px-6">
          <div>{props.value.text}</div>
        </div>
      </div>
    )
  }

  updateSearchTerm = (e) => {
    this.setState({ searchTerm: e.target.value ? e.target.value : null });
  }

  Search = () => {
    return (
      <div className="bg-white sm:rounded-lg shadow m-5 px-4 py-5">
        <h3 className='text-3xl leading-6 font-bold text-gray-900 mb-5'>Search</h3>
        <div className="mt-1 relative flex items-center">
          <input type="text" onChange={this.updateSearchTerm} name="search" id="search" className="shadow-sm bg-gray-100 block w-full p-4 text-lg sm:text-sm border-gray-300 rounded-md" placeholder='Search...' />
        </div>
      </div>
    )
  }

  ReviewCount = (props) => {
    const reviewCount = props.reviews.length;
    const badReviews = props.reviews.filter(review => review.stars < 3).length
    const goodReviews = props.reviews.filter(review => review.stars === 5).length
    const ratio = (goodReviews / (goodReviews + badReviews)) * 100;

    return (
      <div className='m-5'>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <dt className="text-sm font-medium text-gray-500 truncate">Review count</dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">{reviewCount}</dd>
          </div>

          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <dt className="text-sm font-medium text-gray-500 truncate">1 or 2 stars</dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">{badReviews}</dd>
          </div>

          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <dt className="text-sm font-medium text-gray-500 truncate">5 stars</dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">{goodReviews}</dd>
          </div>

          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <dt className="text-sm font-medium text-gray-500 truncate">Sentiment</dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">{Math.round(ratio)}%</dd>
          </div>
        </dl>
      </div>
    )
  }

  isNull = (review) => {
    return review.text !== null;
  }

  performSearch = (review) => {
    if (this.state.searchTerm === null)
      return review;

    return review.text.includes(this.state.searchTerm);
  }

  flattenScrape = (scrape) => {
    return scrape.reviews.map((review) => ({
      ...review,
      title: scrape.title,
      reviewsCount: scrape.reviewsCount,
      reviewsDistribution: scrape.reviewsDistribution
    }))
  }

  render() {
    let formattedReviews = scrapes.map(this.flattenScrape).flat().filter(this.isNull).filter(this.performSearch);
    return (
      <div className="App">
        <this.Search />
        <this.ReviewCount reviews={formattedReviews} />
        {formattedReviews.filter((review) => review.stars < 3).map((review, i) =>
          <this.Review key={i} value={review} />
        )}
      </div>
    );
  }
}

export default App;
